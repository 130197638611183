/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use "material-icons";
@use "@taures/angular-commons/taures.theme" as taures-theme;

@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import '@taures/angular-commons/taures.styles';

html, body {
  height: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-form-field {
  --mat-form-field-container-text-line-height: 1.125;
  --mat-form-field-container-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-line-height: 1.125;
}

/**
  Workaround for https://github.com/angular/components/issues/16757
 */
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  width: auto !important;
  height: auto !important;
}

@include mat.form-field-density(-1);
@include mat.icon-button-density(-2);

.dense-2 {
  @include mat.all-component-densities(-2);
}

.mat-mdc-card-title {
  --mat-card-title-text-size: 24px;
}

.mdc-button:not(.mat-calendar-period-button) {
  letter-spacing: 0;
}

//form field inactive background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: #FAFAFA;
}

//form field label color
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label {
  color: rgba(18, 18, 18, 0.6);
}

//form field focus color
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  background-color: rgb(0, 0, 0);
  opacity: 0.06;
}

.icon-button-20.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 20px;
  line-height: 1;
  padding: 10px;
  .mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
}

.icon-button-21.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 21px;
  line-height: 1;
  padding: 10px;
  .mat-icon {
    width: 21px;
    height: 21px;
    font-size: 21px;
  }
}

.icon-button-18.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 18px;
  line-height: 1;
  padding: 11px;
  .mat-icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
}

.light-hover.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08 !important;
  background-color: #ffffff
}

.mat-badge-medium.mat-badge-warn {
  --mat-badge-container-offset: -18px;
  --mat-badge-background-color: #{mat.get-color-from-palette(taures-theme.$taures-warning)};
  --mat-badge-text-color: #{mat.get-contrast-color-from-palette(taures-theme.$taures-warning, 800)};
}
